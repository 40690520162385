$ = jQuery;
const col_xs_max = 767,
    col_sm_min = 768,
    col_sm_max = 991,
    col_md_min = 992,
    col_md_max = 1199,
    col_lg_min = 1200,
    col_xl_min = 1600,
    easingSpeed = 400;

let ajaxCall = "";

/* Animations */
$(document).on('click', 'a, button', function (e) {
    if ($(this).data('target') && $(this).data('action')) {
        const target = $(this).data('target');
        const action = $(this).data('action');

        e.preventDefault();

        switch (action) {
            case "toggleClass":
                if ($(this).data('class')) {
                    $(target).toggleClass($(this).data('class'));
                    if ($(this).data('remove-class')) {
                        $(target).removeClass($(this).data('remove-class'));
                    }
                    break;
                } else {
                    break;
                }
            case "":
                break;
        }
    }
});

$(document).ready(function () {
    /* Forms */
    $('input[type=checkbox]').each(function () { //Wrap checkbox in checkbox wrapper if it doesn't exists yet
        if (!$(this).parent().hasClass('checkbox-wrapper')) {
            $(this).wrap('<span class="checkbox-wrapper"></span>');
            $(this).after('<span class="custom-checkbox"></span>');
        }
    });
    $('input[type=radio]').each(function () { //Wrap checkbox in checkbox wrapper if it doesn't exists yet
        if (!$(this).parent().hasClass('radio-wrapper')) {
            $(this).wrap('<span class="radio-wrapper"></span>');
            $(this).after('<span class="custom-radio"></span>');
        }
    });
    $('*:not(.search-form) > form:not(.search-form) input[type=submit]').each(function () {
        if ($(this).parent().hasClass('submit-wrapper')) {
            const submit_wrapper = $(this).parent('.submit-wrapper');
            if (!submit_wrapper.hasClass('btn')) {
                submit_wrapper.removeClass();
                submit_wrapper.addClass('submit-wrapper').addClass('btn').addClass('btn-secondary');
            }
        } else {
            $(this).wrap('<div class="submit-wrapper btn btn-secondary"></div>');
        }
    });
    $('select').each(function () {
        if (!$(this).parent().hasClass('select-wrapper')) {
            $(this).wrap('<div class="select-wrapper"></div>');
        }
    });
    $('.submit-wrapper').on('click', function (e) {
        if (!$(e.target).is('input[type=submit]')) {
            $(this).find('input[type=submit]').trigger('click');
        }
    });

    /* Select wrapper */
    let x, i, j, selElmnt, a, b, c;
    /*look for any elements with the class "select-wrapper":*/
    x = document.getElementsByClassName("select-wrapper");
    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        /*for each element, create a new DIV that will act as the selected item:*/
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        /*for each element, create a new DIV that will contain the option list:*/
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 0; j < selElmnt.length; j++) {
            /*for each option in the original select element,
            create a new DIV that will act as an option item:*/
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            c.addEventListener("click", function (e) {
                /*when an item is clicked, update the original select box,
                and the selected item:*/
                let y, i, k, s, h;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                for (i = 0; i < s.length; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
                h.click();
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            /*when the select box is clicked, close any other select boxes,
            and open/close the current select box:*/
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
        });
    }

    function closeAllSelect(elmnt) {
        /*a function that will close all select boxes in the document,
        except the current select box:*/
        let x, y, i, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        for (i = 0; i < y.length; i++) {
            if (elmnt == y[i]) {
                arrNo.push(i)
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (i = 0; i < x.length; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
            }
        }
    }

    $('body').on('click', function (e) {
        if ($(e.target).closest('.select-wrapper').length) {
            return;
        }

        closeAllSelect();
    });

    /* Extra */
    $("a[href^='#']").on('click', function (e) {
        e.preventDefault();
        if ($(this).attr("href") !== '#') {
            scrollToTarget($(this).attr("href"));
        }
    });

    $('.video-wrapper video').on('play', function (e) {
        $(this).attr('controls', '');
    });

    $('.btn-play-video, .video-wrapper video').on('click', function (e) {
        $('.video-wrapper video').trigger('play');
        $('.btn-play-video').remove();
    });

    const inputUniqueKeyField = $('.input-unique-key-field'),
        maxLengthKey = 8,
        allowedChars = /^[0-9a-zA-Z]+$/;

    inputUniqueKeyField.on('focus', 'div[class^="input-unique-key-field-"]', function () {
        if (!$(this).hasClass('input-unique-key-field-1') && $(this).text() === '' && $(this).prev().text() === '') {
            $(this).prev().focus();
        }

        if ($(this).text() !== '') {
            setEndOfContenteditable(this);
        }
    });
    inputUniqueKeyField.on('keydown', 'div[class^="input-unique-key-field-"]', function (e) {
        const keyPressed = e.which || e.keyCode;

        if (keyPressed !== undefined && keyPressed === 8 && $(this).text().length === 0 && !$(this).hasClass('input-unique-key-field-1')) {
            $(this).prev().focus();
            const initial = $(this).prev().val();
            $(this).prev().val('').val(initial);
        }

        if (
            (keyPressed !== undefined && keyPressed === 13)
            || (e.key !== undefined && !e.key.match(allowedChars))
            || ($(this).text().length === 1 && keyPressed !== 46 && keyPressed !== 8)
        ) {
            e.preventDefault();
            return false;
        }
    });
    inputUniqueKeyField.on('keyup paste', 'div[class^="input-unique-key-field-"]', function (e) {
        const chars = $(this).text().length;
		const keyPressed = e.which || e.keyCode;

        if (e.type === 'keyup') {
            validateInputUniqueKey(e, $(this), $(this).text(), allowedChars, false);
        } else if (e.type === 'paste') {
            validateInputUniqueKey(e, $(this), getClipboardText(e), allowedChars, true);
        }

        let uniqueKey = '';
        $('.input-unique-key-field > div').each(function () {
            uniqueKey += $(this).text();

            if ($(this).text() === '' && $(this).hasClass('filled')) {
                $(this).removeClass('filled');
            }
        });

        if (uniqueKey.length === maxLengthKey) {
            $('.input-unique-key input[type=text]').val(uniqueKey);
            $('.form-unique-key').removeClass('hidden');
        } else if (!$('.form-unique-key').hasClass('hidden')) {
            $('.form-unique-key').addClass('hidden');
        }
		
		if (keyPressed !== undefined && keyPressed === 8 && $(this).text().length === 0 && !$(this).hasClass('input-unique-key-field-1')) {
            $(this).next().focus();
        }
    });

    $('.forms .wpcf7').on('wpcf7mailsent', function (e) {
        $('.input-unique-key-wrapper, .form-unique-key, .form-receipt, .receipt-buttons-wrapper, .heading .content-area').remove();
    });

    const formUniqueKey = $('.form-unique-key-wrapper'),
        formReceipt = $('.form-receipt'),
        receiptStartWrapper = $('.receipt-button-start'),
        receiptBackWrapper = $('.receipt-button-back'),
        maxFileSize = 2,
        btnReceipt = $('.btn-receipt'),
        formReceiptResponse = $('.form-receipt-wrapper .wpcf7-response-output');

    formUniqueKey.on('wpcf7invalid', '.wpcf7', function (e) {
        if (e.detail !== undefined && e.detail.apiResponse !== undefined && e.detail.apiResponse.invalid_fields !== undefined) {
            const invalidFields = e.detail.apiResponse.invalid_fields;
            let invalidUniqueKey = false;

            for (let i = 0; i < invalidFields.length; ++i) {
                if (invalidFields[i].into === 'span.wpcf7-form-control-wrap.unique-key') {
                    invalidUniqueKey = true;
                }
            }

            const formInputUniqueKey = $('form .input-unique-key');
            if (invalidUniqueKey && !formInputUniqueKey.hasClass('error')) {
                formInputUniqueKey.addClass('error');
            } else if (!invalidUniqueKey) {
                formInputUniqueKey.removeClass('error');
            }
        }
    });

    btnReceipt.on('click', function (e) {
        e.preventDefault();
        $('.form-receipt input[type=file][name=receipt]').click();
    });

    $('.btn-code').on('click', function (e) {
        e.preventDefault();

        if (formUniqueKey.hasClass('hidden')) {
            formUniqueKey.removeClass('hidden');
            formReceipt.addClass('hidden');
            receiptStartWrapper.removeClass('hidden');
            receiptBackWrapper.addClass('hidden');

            if (btnReceipt.hasClass('error')) {
                btnReceipt.removeClass('error');
            } else if ($('.receipt-file-content').hasClass('error')) {
                $('.receipt-file-content').removeClass('error');
                $('.form-receipt-wrapper form input[type=submit]').prop('disabled', false);
            }
            if (formReceiptResponse.hasClass('wpcf7-response-error')) {
                formReceiptResponse.html('').removeClass('wpcf7-response-error');
            }

            if ($('.form-receipt-wrapper form').hasClass('invalid')) {
                $('.form-receipt-wrapper form').removeClass('invalid').addClass('init');
                $('.form-receipt-wrapper form input.wpcf7-not-valid').each(function () {
                    $(this).val('').removeClass('wpcf7-not-valid');
                    $(this).next('.wpcf7-not-valid-tip').remove();
                });
                $('.form-receipt-wrapper form input[type=checkbox]:checked').each(function () {
                    $(this).prop('checked', false);
                });
            }

            scrollToTarget("#content .heading", -30);
        }
    });

    $('.form-receipt input[type=file][name=receipt]').on('change', function (e) {
        if (e.target && e.target.files && e.target.files[0] !== undefined && e.target.files[0].name !== undefined && e.target.files[0].name !== '') {
            if (e.target.files[0].size / 1024 / 1024 > maxFileSize) {
                if (!formReceipt.hasClass('hidden')) {
                    $('.receipt-file-content').addClass('error');
                } else {
                    btnReceipt.addClass('error');
                }

                $('.form-receipt-wrapper form input[type=submit]').prop('disabled', true);

                const formId = $('.form-receipt-wrapper input[name="_wpcf7"]').val();
                if (wpcf7Response !== undefined && formId) {
                    formReceiptResponse.html(wpcf7Response[formId].error).addClass('wpcf7-response-error');
                }
                return;
            }

            if (btnReceipt.hasClass('error')) {
                btnReceipt.removeClass('error');
            } else if ($('.receipt-file-content').hasClass('error')) {
                $('.receipt-file-content').removeClass('error');
                $('.form-receipt-wrapper form input[type=submit]').prop('disabled', false);
            }
            if (formReceiptResponse.hasClass('wpcf7-response-error')) {
                formReceiptResponse.html('').removeClass('wpcf7-response-error');
            }

            const reader = new FileReader();

            reader.onload = function (e) {
                if ($('.receipt-file-img').hasClass('IE-browser')) {
                    $('.receipt-file-img.IE-browser').css('background-image', 'url("' + e.target.result + '")');
                } else {
                    $('.receipt-file-img img').attr('src', e.target.result);
                }

                if (!formUniqueKey.hasClass('hidden')) {
                    formUniqueKey.addClass('hidden');
                    formReceipt.removeClass('hidden');
                    receiptStartWrapper.addClass('hidden');
                    receiptBackWrapper.removeClass('hidden');
                }
            };

            reader.readAsDataURL(e.target.files[0]);

            $('.receipt-file-title').text(e.target.files[0].name);
        }
    });

    $(window).on('load', function (e) {
        if ($('form.wpcf7-form').length > 0) {
            $('form.wpcf7-form').each(function (e) {
               disabledSubmitWrapper(this);
            });
        }
    });
    $('form .wpcf7-acceptance input[type=checkbox]').on('change', function (e) {
        disabledSubmitWrapper(this);
    });
});

function showPlaceholderText(placeholderLength) {
    let placeholderText = '';

    for (let i = 0; i < placeholderLength; ++i) {
        placeholderText += '0';
    }

    return placeholderText;
}

function dynamicSpacing(element, parent_element) {
    $(element).css({
        'letter-spacing': 0,
        'word-wrap': 'none',
    });
    let content = $(element).html();
    let original = content;
    content = content.replace(/(\w|\s)/g, '<span>$1</span>');
    $(element).html(content);

    let letter_width = 0;
    let letters_count = 0;
    $(element + ' span').each(function () {
        letter_width += $(this).width();
        letters_count++;
    });

    let h1_width = $(parent_element).width();

    let spacing = (h1_width - letter_width) / (letters_count - 1);

    $(element).html(original);

    if (spacing < 0) {
        spacing = 0;

        $(element).css({
            'word-wrap': 'break-word',
        });
    }

    $(element).css('letter-spacing', spacing);
}

function scrollToTarget(target, offset) {
    if (offset === undefined && offset === '') {
        offset = 0;
    }

    target = $(target);
    if (target.length) {
        const adminBar = $('#wpadminbar');
        if (adminBar.length) {
            $("html, body").animate({scrollTop: target.offset().top - adminBar.outerHeight() + offset}, easingSpeed);
        } else {
            $("html, body").animate({scrollTop: target.offset().top + offset}, easingSpeed);
        }
    }
}

function setEndOfContenteditable(contentEditableElement) {
    let range, selection;
    if (document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
    {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
    } else if (document.selection)//IE 8 and lower
    {
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
    }
}

function validateInputUniqueKey(e, element, value, allowedChars, isPasteEvent) {
    const chars = value.length;

    if (chars > 1 || (value !== '' && !value.match(allowedChars))) {
        element.html('<br>');
        e.preventDefault();
        return false;
    } else if (chars === 1) {
        if (!isPasteEvent) {
            element.addClass('filled');

            if (!element.hasClass('input-unique-key-field-8')) {
                element.next().focus();
            }
        } else {
            setTimeout(function () {
                element.addClass('filled');

                if (!element.hasClass('input-unique-key-field-8')) {
                    element.next().focus();
                }
            }, 100);
        }
    } else if (element.hasClass('filled')) {
        if (!isPasteEvent) {
            element.removeClass('filled');

            if (!element.hasClass('input-unique-key-field-1')) {
                element.prev().focus();
            }
        } else {
            setTimeout(function () {
                element.removeClass('filled');

                if (!element.hasClass('input-unique-key-field-1')) {
                    element.prev().focus();
                }
            }, 100);
        }
    }
}

function disabledSubmitWrapper(element) {
    const form = $(element).hasClass('.wpcf7-form') ? $(element) : $(element).closest('form.wpcf7-form');
    let allAccepted = true;
    $(form).find('.wpcf7-acceptance input[type=checkbox]').each(function (e) {
        if (!$(this).is(':checked')) {
            allAccepted = false;
        }
    });

    if ($(form).find('.receipt-file-content').length > 0) {
        if ($('.receipt-file-content').hasClass('error') || !allAccepted) {
            $(form).find('input[type=submit]').prop('disabled', true);

            if (!$(form).find('.submit-wrapper').hasClass('disabled')) {
                $(form).find('.submit-wrapper').addClass('disabled');
            }
        } else {
            $(form).find('input[type=submit]').prop('disabled', false);

            if ($(form).find('.submit-wrapper').hasClass('disabled')) {
                $(form).find('.submit-wrapper').removeClass('disabled');
            }
        }
    } else {
        if (!allAccepted) {
            $(form).find('input[type=submit]').prop('disabled', true);

            if (!$(form).find('.submit-wrapper').hasClass('disabled')) {
                $(form).find('.submit-wrapper').addClass('disabled');
            }
        } else {
            $(form).find('input[type=submit]').prop('disabled', false);

            if ($(form).find('.submit-wrapper').hasClass('disabled')) {
                $(form).find('.submit-wrapper').removeClass('disabled');
            }
        }
    }
}

function getClipboardText(e) {
    var clipboardData = e.clipboardData || e.originalEvent.clipboardData || window.clipboardData;
    var clipboardText = '';

    if (clipboardData !== undefined) {
        clipboardText = clipboardData.getData('Text');

        if (clipboardText === '') {
            clipboardText = clipboardData.getData('text/plain');
        }
    }

    return clipboardText;
}

let isIE = /*@cc_on!@*/false || !!document.documentMode, // Internet Explorer 6-11
    isEdge = !isIE && !!window.StyleMedia; // Edge 20+
// Check if Internet Explorer 6-11 OR Edge 20+
if (isIE) {
    $('.picture-container').each(function () {
        let $container = $(this),
            imgUrl = $container.find('img').prop('src');
        if ($container.find('img').hasClass('dwd-lazyload')) {
            imgUrl = $container.find('img').data('src');
        }
        if (imgUrl) {
            $container
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass('IE-browser');
        }
    });
    $(':file').addClass('IE-browser');
    $('svg').addClass('IE-browser');
}
if (isEdge) {
    $(':file').addClass('IE-browser');
}
if (navigator.userAgent.indexOf("Firefox") > 0) {
    $(':file').addClass('FF-browser');
}

$( function() {
    const inputDay = $('.input-day'),
        inputMonth = $('.input-month'),
        inputYear = $('.input-year');

    function getAge(dateString) {
        if(dateString != "")
        {
            var now = new Date();
            var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            var birthDate = new Date(dateString);
            var birthDateNow = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
            var age;

            age = today.getFullYear() - birthDate.getFullYear();

            if (today < birthDateNow) {
                age = age-1;
            }

            if(!isNaN(age) && age >=18 && age < 100)
            {
                setCookie("age_gate", 1, 3600000);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }


    $(document).on('keydown', '.input-day, .input-month, .input-year', function (e) {
        if (e.shiftKey == true) {
            e.preventDefault();
            return false;
        }

        if (!((e.keyCode == 190) || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 46)) {
            //not a number key or period so prevent
            e.preventDefault();
            return false;
        }

        var allowedDigits = 1;
        var length = $(this).val().length;


        if ($(this).hasClass('input-year')) {
            allowedDigits = 3;
        }

        if(length > allowedDigits){
            $(this).val("");
        }
    });


    $(document).on('keyup paste', '.input-day, .input-month, .input-year', function (e) {

        if (inputDay.val() != '' && inputDay.val().length <= 2
            && inputMonth.val()  != ''  && inputMonth.val().length <= 2
            && inputYear.val() != '' && inputYear.val().length == 4 ){
            validateBirthday()
        }

        if ($(this).hasClass('input-day') && $(this).val().length == 2){
            inputMonth.focus();
        }

        if ($(this).hasClass('input-month') && $(this).val().length == 2){
            inputYear.focus();
        }
    });


    function validateBirthday()
    {
        var day = inputDay.val(),
            dayMax = parseInt(inputDay.attr('max')),
            month = inputMonth.val(),
            monthMax = parseInt(inputMonth.attr('max')),
            year = inputYear.val(),
            yearMax = parseInt(inputYear.attr('max')),
            error = 0;

        if (day > dayMax || day == '00') {
            error++;
        }

        if (month > monthMax || month == '00') {
            error++;
        }

        if (year > yearMax || year == '0000') {
            error++;
        }

        if (!error){
            //if (getAge(year + ', '+month+', '+day)) {
            if (getAge(month + '/'+day+'/'+year)) {
                $('.age-lock').css('display','none');
                $('.agelock-message').css('display','none');
                $('.forms').css('display','block');
                $('.form-message').css('display','block');

            } else {
                showErrorMessage();
            }
        } else {
            showErrorMessage();
        }
    }

    function showErrorMessage() {
        $('.age-lock .response-output').removeClass('hidden');
        $('.age-lock').addClass('invalid');
    }

    function setCookie(cname, cvalue, milliseconds) {
        const d = new Date();
        d.setTime(d.getTime() + milliseconds);
        const expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function daysToTime(days) {
        return days * 24 * 60 * 60 * 1000;
    }
});